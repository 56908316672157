<template>
  <LoadingSpinner />
</template>
<script>
import LoadingSpinner from '../components/LoadingSpinner.vue'
export default {
  components: {
    LoadingSpinner
  }
}
</script>